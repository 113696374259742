import classNames from 'classnames';
import { Link } from 'gatsby';
import { values } from 'lodash';
import React from 'react';

export default function Button({
  name,
  clickHandler,
  label = '',
  ariaLabel = '',
  w = 'min-w-[13.75rem]',
  h = 'md:min-h-[3.125rem] min-h-[2.813rem]',
  px = 'px-10',
  py = 'py-2',
  bg,
  hover,
  text,
  font = 'font-light not-italic',
  border,
  shadow = '',
  url = '',
  target = '_self',
  leading = '',
  rounded = '100rem',
  external = false,
  disabled = 'disabled:opacity-10 disabled:pointer-events-none',
  isDisabled = false,
  variant,
  reverse,
  transition = 'transition duration-300 ease-in-out',
}) {
  const callback = (cl) => cl;

  const variants = {
    primaryText: {
      bg: 'bg-transparent',
      hover: `hover:text-black hover:bg-transparent`,
      text: `lg:text-[18px] text-[15px] text-primary underline 
      rtl:font-almarai ltr:font-space-medium`,
    },
    primaryWhite: {
      bg: 'bg-transparent',
      hover: `hover:text-black hover:bg-white hover:border-bg-black`,
      text: `lg:text-[18px] text-[15px] text-white relative
       before:content-[''] before:border before:border-text-black
      before:border-t-0 before:border-r-[1px] before:border-b-[1px] before:border-l-0 before:p-[4px]
      before:z-0 before:!inline before:relative before:ltr:rotate-[-45deg] 
      before:rtl:rotate-[135deg] flex-row-reverse gap-x-2`,
      border: 'border border-bg-white',
    },
    primaryBlack: {
      bg: 'bg-transparent',
      hover: `hover:text-white hover:bg-black hover:border-bg-black`,
      text: `lg:text-[18px] text-[15px] text-black relative before:content-[''] 
      before:border before:border-text-black
      before:border-t-0 before:border-r-[1px] before:border-b-[1px] before:border-l-0 before:p-[4px]
      before:z-0 before:!inline before:relative before:ltr:rotate-[-45deg] 
      before:rtl:rotate-[135deg] flex-row-reverse gap-x-2`,
      border: 'border border-bg-black',
    },
    primaryBlue: {
      bg: 'bg-transparent',
      hover: `hover:text-blue-dark hover:bg-white hover:border-blue-dark`,
      text: `lg:text-[18px] text-[15px] text-white relative before:content-[''] 
      before:border before:border-text-black
      before:border-t-0 before:border-r-[1px] before:border-b-[1px] before:border-l-0 before:p-[4px]
      before:z-0 before:!inline before:relative before:ltr:rotate-[-45deg] 
      before:rtl:rotate-[135deg] flex-row-reverse gap-x-2`,
      border: 'border border-bg-white',
    },
    primaryOrange: {
      bg: 'bg-transparent',
      hover: `hover:text-orange hover:bg-white hover:border-orange`,
      text: `lg:text-[18px] text-[15px] text-white relative before:content-[''] 
      before:border before:border-text-black
      before:border-t-0 before:border-r-[1px] before:border-b-[1px] before:border-l-0 before:p-[4px]
      before:z-0 before:!inline before:relative before:ltr:rotate-[-45deg] 
      before:rtl:rotate-[135deg] flex-row-reverse gap-x-2`,
      border: 'border border-bg-white',
    },
  };

  const getVariant = () => {
    if (variant) {
      const objValues = values(variants[variant]);
      const str = objValues.join(' ');
      return str;
    }
    return '';
  };

  const classname = classNames({
    flex: true,
    'cursor-pointer': true,
    'items-center': true,
    'justify-center': true,
    'flex-row-reverse': reverse,
    [`${callback(w)}`]: w,
    [`${callback(h)}`]: h,
    [`${callback(px)}`]: px,
    [`${callback(py)}`]: py,
    [`${callback(bg)}`]: bg,
    [`${callback(hover)}`]: hover,
    [`${callback(text)}`]: text,
    [`${callback(font)}`]: font,
    [`${callback(leading)}`]: leading,
    [`${callback(shadow)}`]: shadow,
    [`${callback(rounded)}`]: rounded,
    [`${callback(disabled)}`]: disabled,
    [`${callback(transition)}`]: transition,
    [`${callback(border)}`]: border,
  });

  if (label) {
    if (external && url)
      return (
        <a
          aria-label={ariaLabel}
          href={url}
          target="_blank"
          className={`${getVariant()} ${classname}`}
          rel="noreferrer"
        >
          {label}
        </a>
      );

    return url ? (
      <Link
        download={name !== "" ? name : false}
        aria-label={ariaLabel}
        to={url}
        target={name === "" ? target : "_blank"}
        onClick={clickHandler}
        className={`${getVariant()} ${classname}`}
      >
        {label}
      </Link>
    ) : (
      <button
        aria-label={ariaLabel}
        type="button"
        onClick={clickHandler}
        disabled={isDisabled}
        className={`${getVariant()} ${classname}`}
      >
        {label}
      </button>
    );
  }
  return null;
}
